import React from 'react'

import { images } from '../../constants'
import { motion } from 'framer-motion';
import { AppWrap } from '../../wrapper'

import useMouse from "@react-hook/mouse-position";

import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      ease: 'easeInOut',
      duration: 1,
    }
  }
}

const Header = () => {

  const ref = React.useRef(null)


  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  })


  return (
    <div ref={ref} className="app__header app__flex">
      <motion.div
        whileInView={{ x:[-100, 0], opacity: [0, 1]}}
        transition={{ duration: 0.5}}
        className="app__header-info"
      >
        <div className="app__header-badge" >
          <div className="badge-cmp app__flex" style={{transform: `translateX(${((mouse.elementWidth/2)-mouse.x)/-40}px) translateY(${((mouse.elementWidth/2)-mouse.y)/-40}px)`}} > 
            <span>👋</span>
            <div style={{ marginLeft: 20}}>
              <p className="p-text">Hello, I'm </p>
              <h1 className="head-text">Dara</h1> 
            </div>
          </div>
           <div className="tag-cmp app__flex" style={{transform: `translateX(${((mouse.elementWidth/2)-mouse.x)/-40}px) translateY(${((mouse.elementWidth/2)-mouse.y)/-40}px)`}}>
           <p className="p-text">Fullstack Developer </p>
           <p className="p-text">Kids Tutor</p>

           </div>
        </div>

      </motion.div>
        
        <motion.div
           whileInView={{ opacity: [0, 1]}}
           transition={{ duration: 0.5, delayChildren: 0.5}}
           className="app__header-img"

        >
          <img src={images.profile} alt="profile_bg" 
            style={{transform: `translateX(${((mouse.elementWidth/2)-mouse.x)/50}px) translateY(${((mouse.elementWidth/2)-mouse.y)/50}px)`}}
          />
          <motion.img
            whileInView={{ scale: [0, 1]}}
            transition={{ duration: 1, ease: "easeInOut"}}
            className="overlay_circle"
            src={images.circle}
            alt="profile_circle"

          />

        </motion.div>

        <motion.div
          variants={scaleVariants}
          whileInView={scaleVariants.whileInView}
          className="app__header-circles"
        >
          {[images.node, images.react, images.sass].map((circle, index) => (
            
            <div className="circle-cmp app__flex" style={{transform: `translateX(${((mouse.elementWidth/2)-mouse.x)/-40}px) translateY(${((mouse.elementWidth/2)-mouse.y)/-40}px)`}} key={`circle-${index}`}>
              <img src={circle} alt="circle" />
            </div>
          ))}
        </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home')