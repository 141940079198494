import React from 'react'
import { scroller } from 'react-scroll';


const NavigationDots = ( {active} ) => {

    const scrollTo = (item) => {
        // console.log(item)
        scroller.scrollTo(item, {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
        }
        );
      };

  return (
    <div className="app__navigation">
        {['home', 'about', 'work', 'skills', 'testimonials', 'contact'].map((item, index) =>(
            <div 
                onClick={() => scrollTo(item)} 
                // href={`#${item}`} 
                // onClick={() => setToggle(false)}
                key={item + index}
                className="app__navigation-dot"
                style={active === item ? {backgroundColor: '#313bac'} : { }}
            />
              
          ))}
    </div>
  )
}

export default NavigationDots