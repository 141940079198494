import React from 'react'
import { BsInstagram } from 'react-icons/bs'
import {FaFacebook, FaLinkedinIn } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
          <a href="https://www.linkedin.com/in/dara-adesoji-485a1699/" target="_blank" rel="noopener noreferrer">

            <FaLinkedinIn />
          </a>
        </div>
        <div>
          <a href="https://www.facebook.com/dara.adesoji.9" target="_blank" rel="noopener noreferrer">

            <FaFacebook />
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/_davilla_/" target="_blank" rel="noopener noreferrer">

            <BsInstagram />
          </a>
        </div>
    </div>
  )
}

export default SocialMedia